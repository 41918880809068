
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default function Book() {

    return (
    < Layout >
        <Helmet defer="true">

            <script type="text/javascript" src="https://meeting-widget.getgist.com/" defer></script>

        </Helmet>


        <div class="meetings-iframe-container pb-10 bg-gray-50" data-src="https://getgist.me/mark28733xs/meeting?embed=true"></div>

    </Layout >
    )

}